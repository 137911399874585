import React from 'react'
import dynamic from 'next/dynamic'

const NotReady = dynamic(() => import('@/components/NotReady'))
const AlertInquiry = dynamic(() => import('@/features/AlertInquiry'))
const AlertResult = dynamic(() => import('@/features/AlertResult'))
const AppLogHome = dynamic(() => import('@/features/AppLogHome'))
const AuditLogHome = dynamic(() => import('@/features/AuditLogHome'))
const BlackListHome = dynamic(() => import('@/features/BlackListHome'))
const CmpGuide = dynamic(() => import('@/features/CmpGuide'))
const CodeHome = dynamic(() => import('@/features/CodeHome'))
const ColumnJusticeSearch = dynamic(() => import('@/features/ColumnJusticeSearch'))
const DisapprovalRuleHome = dynamic(() => import('@/features/DisapprovalRuleHome'))
const DisapprovalTriggerMgmt = dynamic(() => import('@/features/DisapprovalTriggerHome'))
const ErrorCodesHome = dynamic(() => import('@/features/ErrorCodesHome'))
const FdsListTemplate = dynamic(() => import('@/features/FdsListTemplate'))
const FranchiseReview = dynamic(() => import('@/features/FranchiseReview'))
const JobLogHome = dynamic(() => import('@/features/JobLogList'))
const JobsHome = dynamic(() => import('@/features/JobList'))
const MetaGlossarySearch = dynamic(() => import('@/features/MetaGlossarySearch'))
const MetaWordSearch = dynamic(() => import('@/features/MetaWordSearch'))
const NavMenusHome = dynamic(() => import('@/features/NavMenusHome'))
const NavPagesHome = dynamic(() => import('@/features/NavPagesHome'))
const PadRuleSearch = dynamic(() => import('@/features/PadRuleSearch'))
const PdsEdit = dynamic(() => import('@/features/PdsEdit'))
const PdsHome = dynamic(() => import('@/features/PdsHome'))
const ReviewProcess = dynamic(() => import('@/features/ReviewProcess'))
const ReviewScreen = dynamic(() => import('@/features/ReviewScreen'))
const RuleInterfaceHome = dynamic(() => import('@/features/RuleInterfaceHome'))
const RuleHome = dynamic(() => import('@/features/RuleHome'))
const RuleResultHome = dynamic(() => import('@/features/RuleResultHome'))
const RuleTemplateHome = dynamic(() => import('@/features/RuleTemplateHome'))
const TestRuleSearch = dynamic(() => import('@/features/TestRuleSearch'))
const TodaysFds = dynamic(() => import('@/features/TodaysFds'))
const UserMgmtHome = dynamic(() => import('@/features/UserMgmtHome'))
const RoleUserHome = dynamic(() => import('@/features/RoleUserHome'))
const RoleSettingsHome = dynamic(() => import('@/features/RoleSettingsHome'))
const PageApiRoleHome = dynamic(() => import('@/features/PageApiRoleHome'))
const MsgMngHome = dynamic(() => import('@/features/MsgMngHome'))
const MsgMngEnumHome = dynamic(() => import('@/features/MsgMngEnumHome'))
const VirtualTrans = dynamic(() => import('@/features/VirtualTransHome'))

export interface ISceneComponent {
  url: string
  title: string
  component: React.ComponentType | React.ReactNode
}

/**
 * 배열을 받아 키가 url 이고 값이 {url, title, component} 인 객체를 리턴한다
 * reduce가 사용됐기 때문에 한 객체안에 전체 키와 값이 들어감 (배열아님)
 */
function createScenes(
  scenes: Array<[string, string, React.ComponentType]>,
): Record<string, ISceneComponent> {
  return scenes.reduce((acc, cur) => {
    const [url, title, component] = cur
    acc[url] = { url, title, component }
    return acc
  }, {} as Record<string, ISceneComponent>)
}

// TODO 제목에 비어 있는 부분 채워야 함
// 메인 레이아웃 안에 포함할 URL만 추가한다
export const SCENES: Record<string, ISceneComponent> = createScenes([
  ['/meta/words', '메타 단어', MetaWordSearch],
  ['/meta/glossary', 'FDS 메타 용어', MetaGlossarySearch],
  ['/meta/column-justice', '컬럼 매핑 정의', ColumnJusticeSearch],
  ['/code', '코드 조회', CodeHome],
  ['/error-codes', '에러 코드 관리', ErrorCodesHome],
  ['/log/app-logs', '시스템 로그', AppLogHome],
  ['/log/audit-logs', 'AUDIT 로그', AuditLogHome],
  ['/log/job-logs', '잡 로그', JobLogHome],
  ['/log/jobs', '잡 목록', JobsHome],
  ['/meta/column-justice', '컬럼 정의', ColumnJusticeSearch],
  ['/meta/glossary', '용어조회', MetaGlossarySearch],
  ['/meta/words', '단어 조회', MetaWordSearch],
  ['/nav/list', '메뉴 모음', NavMenusHome],
  ['/nav/pages', '페이지 목록', NavPagesHome],
  ['/pds/list', '자료실', PdsHome],
  ['/pds/edit', '자료실 등록', PdsEdit],
  ['/pds/edit/[pdsId]', '자료실 수정', PdsEdit],
  ['/alert-inquiry', 'ALERT 내역조회', NotReady], // AlertInquiry
  ['/alert-list-rule', 'RULE 별 ALERT LIST', NotReady],
  ['/alert-result', 'ALERT 결과조회', AlertResult],
  ['/assignment-setting', '할당설정', NotReady],
  ['/assignment-status', '할당현황', NotReady],
  ['/bl-mgmt', 'BL 관리', BlackListHome],
  ['/cmp-guide', 'Component 가이드', CmpGuide],
  ['/developing', 'RULE 관리', NotReady],
  ['/disapproval-rule-mgmt', '비승인 룰 관리', DisapprovalRuleHome],
  ['/disapproval-trigger-mgmt', '비승인 트리거 관리', DisapprovalTriggerMgmt],
  ['/eai-error-lookup', 'EAI 룰 ERROR 내역 조회', NotReady],
  ['/eai-interface-mgmt', 'EAI 인터페이스 정보 관리', NotReady],
  ['/eai-rule-alert', 'EAI RULE ALERT', NotReady],
  ['/eai-rule-error', 'EAI RULE ERROR', NotReady],
  ['/eai-rule-mgmt', 'EAI RULE 관리', NotReady],
  ['/eai-trigger-mgmt', 'EAI TRIGGER 관리', NotReady],
  ['/fds-list-template', 'FDS LIST 관리', FdsListTemplate],
  ['/first-reject', '선거절 관리', NotReady],
  ['/franchise-review', '가맹점 REVIEW', FranchiseReview],
  ['/help', '도움말', NotReady],
  ['/index-developing', '개발중', NotReady],
  ['/index', '', TodaysFds],
  ['/interface-info-mgmt', 'EAI 인터페이스 정보 관리', RuleInterfaceHome],
  ['/open-alert', 'ALERT 열기', NotReady],
  ['/pad-rule-search', 'PAD RULE 조회', PadRuleSearch],
  ['/role/settings', '권한 설정', RoleSettingsHome],
  ['/role/user', '사용자 권한 관리', RoleUserHome],
  ['/review-process', 'Review 처리 현황', ReviewProcess],
  ['/review-screen', 'RULE 관리', ReviewScreen],
  ['/reviewactive-developing', 'RULE 관리', NotReady],
  ['/rule-mgmt', 'RULE 관리', RuleHome],
  ['/rule-result-mgmt', 'RULE 결과 관리', RuleResultHome],
  ['/rule-template', 'RULE TEMPLATE 관리', RuleTemplateHome],
  ['/system-setting', '시스템 설정', NotReady],
  ['/test-alert', '테스트 ALERT', NotReady],
  ['/test-rule-search', 'TEST RULE 조회', TestRuleSearch],
  ['/todays-fds', "Today's FDS", TodaysFds],
  ['/user-mgmt', '사용자관리', UserMgmtHome],
  ['/page-api-role', '페이지 권한', PageApiRoleHome],
  ['/msg-mng', '메시지 관리', MsgMngHome],
  ['/msg-mng-enum', '메시지 코드 관리', MsgMngEnumHome],
  ['/fds-virtual-trans', '메시지 코드 관리', VirtualTrans],
])

// url(키)로 값을 리턴해준다
export const findSceneByUrl = (url: string): ISceneComponent | undefined => {
  if (url.endsWith('/')) {
    url = url.replace(/\/+$/, '')
  }
  const scene = SCENES[url]
  return scene ?? undefined
}

// url컴포넌트를 받아서 리액트 노드로 변경해준다 기존 자연스럽게 되던거
export const createSceneElement = (
  component: React.ComponentType | React.ReactNode,
  props?: any,
): React.ReactNode | undefined => {
  if (React.isValidElement(component)) {
    return React.cloneElement(component, props)
  }

  const Comp = component as React.ComponentType
  return <Comp {...props} />
}
