import AnimatedText from '@/components/AnimationText'
import ErrorViewDialog, {
  ErrorViewDialogProps,
} from '@/components/BottomObserverProvider/components/ErrorViewDialog'
import { mainLayoutConstants } from '@/lib/main-layout-constants'
import theme from '@/theme'
import { ErrorType } from '@fds/domain/src/ErrorHandleStore'
import { formatEpochSeconds, formatStrEpochSeconds } from '@local/ui'
import { Box, Stack, Toolbar, Typography, alpha, styled, useMediaQuery } from '@mui/material'
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar'
import clsx from 'clsx'
import { useCallback, useEffect, useRef, useState } from 'react'
import TimeAgo from 'timeago-react'
const { sidemenu, content } = mainLayoutConstants
interface Props {
  isSidebarOpen: boolean
  className?: string
  error: ErrorType | null
  clearError: () => void
  pId?: string
}
interface AppBarProps extends MuiAppBarProps {
  open?: boolean
}
const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
  zIndex: useMediaQuery(theme.breakpoints.down('md'))
    ? theme.zIndex.drawer
    : theme.zIndex.drawer + 1,
  backgroundColor: '#fff',
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  marginLeft: useMediaQuery(theme.breakpoints.down('md')) ? 0 : sidemenu.closeWidth,
  width: useMediaQuery(theme.breakpoints.down('md'))
    ? '100%'
    : `calc(100% - ${sidemenu.closeWidth}px)`,
  ...(open && {
    marginLeft: sidemenu.openWidth,
    width: `calc(100% - ${sidemenu.openWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: '0.2s',
    }),
  }),
  top: 'auto',
  position: 'fixed',
  bottom: 0,
  height: 65,
  borderTop: '1px solid #aaa',
}))
type DialogId = 'ErrorViewDialog'
export default function Bottombar(props: Props) {
  const { className, isSidebarOpen, error, clearError, pId } = props
  const smOrDown = useMediaQuery(theme.breakpoints.down('md'))
  const shift = !!(isSidebarOpen && !smOrDown)
  const rootRef = useRef<HTMLDivElement | null>(null)

  const [errorViewDialogProps, setErrorViewDialogProps] = useState<ErrorViewDialogProps>()
  const [dialogId, setDialogId] = useState<DialogId>()

  const handleCloseDialog = useCallback(() => {
    setDialogId(undefined)
    setErrorViewDialogProps(undefined)
    clearError()
  }, [clearError])

  useEffect(() => {
    if (!error) return
    if (error.msgPrntCd === '2') {
      setDialogId('ErrorViewDialog')
      setErrorViewDialogProps({
        errorMsg: error,
        open: true,
        onClose: handleCloseDialog,
        pId: pId ?? '',
      })
    }
  }, [handleCloseDialog, error])

  const newDate = new Date(Date.now())
  const month = (newDate.getMonth() + 1).toString().padStart(2, '0')
  const day = newDate.getDate().toString().padStart(2, '0')
  const hours = newDate.getHours().toString().padStart(2, '0')
  const minutes = newDate.getMinutes().toString().padStart(2, '0')

  const dateStr = `${month}/${day} ${hours}:${minutes}`
  const [backgroundColor, setBackgroundColor] = useState<string>('#eee')

  useEffect(() => {
    if (error) {
      setBackgroundColor(alpha(theme.palette.primary.light, 0.2)) // 에러가 발생했을 때 배경색 변경

      const timeoutId = setTimeout(() => {
        setBackgroundColor('#eee') // 일정 시간 후에 원래 배경색으로 변경
      }, 2000) // 2초 후에 변경

      return () => clearTimeout(timeoutId) // cleanup 함수
    }
  }, [error])
  return (
    <AppBar
      className={clsx('Bottombar-root', className)}
      position="fixed"
      open={shift && !smOrDown}
      elevation={1}
      ref={rootRef}
    >
      <Toolbar
        sx={{
          whiteSpace: 'nowrap',
          borderBottom: '1px solid #e0e0e0',
        }}
      >
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="flex-start"
          sx={{ width: '100%' }}
        >
          <Typography variant="h5" color="#000" sx={{ mr: 2 }}>
            메시지정보
          </Typography>
          <Stack
            direction="row"
            spacing={3}
            sx={{ border: '1px solid #aaa', px: 2, backgroundColor: backgroundColor }}
          >
            <Stack direction="row" spacing={1} alignItems="center">
              <Typography variant="h6" color="#000">
                TIME :
              </Typography>
              <Box sx={{ color: '#000' }}>
                <Typography variant="h6" color="#000">
                  {error && error.msgPrntCd === '1' ? dateStr : 'NONE'}
                </Typography>

                {/* {error && error.msgPrntCd === '1' ? error.msgCn : 'NONE'} */}
              </Box>
            </Stack>
            <Stack direction="row" spacing={2} alignItems="center">
              <Typography variant="h6" color="#000">
                UI CODE :
              </Typography>
              <Box sx={{ p: 1, color: '#000', minWidth: 80 }}>
                <Typography variant="h6" color="#000">
                  {error && error.msgPrntCd === '1' ? pId : 'NONE'}{' '}
                </Typography>
                {/* {error && error.msgPrntCd === '1' ? pId : 'NONE'} */}
              </Box>
            </Stack>
            <Stack direction="row" spacing={2} alignItems="center">
              <Typography variant="h6" color="#000">
                CODE :
              </Typography>
              <Box sx={{ p: 1, color: '#000', minWidth: 120 }}>
                <Typography variant="h6" color="#000">
                  {error && error.msgPrntCd === '1' ? error.msgId : 'NONE'}
                </Typography>
              </Box>
            </Stack>
            <Stack direction="row" spacing={2} alignItems="center">
              <Typography variant="h6" color="#000">
                MESSAGE :
              </Typography>
              <Box sx={{ p: 1, color: '#000', minWidth: 300 }}>
                <Typography variant="h6" color="#000">
                  {error && error.msgPrntCd === '1' ? error.msgCn : 'NONE'}{' '}
                </Typography>
                {/* {error && error.msgPrntCd === '1' ? error.msgCn : 'NONE'} */}
              </Box>
            </Stack>
          </Stack>
        </Stack>
      </Toolbar>
      {dialogId === 'ErrorViewDialog' && errorViewDialogProps && (
        <ErrorViewDialog {...errorViewDialogProps} />
      )}
    </AppBar>
  )
}
